@use '@angular/material' as mat;
@use "@taures/angular-commons/taures.theme" as theme;

@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/material-icons";
@import "@fontsource/material-icons-outlined";

@import '@taures/angular-commons/taures.styles';

html, body {
  height: 100%;
  font-size: 14px;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@include mat.form-field-density(-1);
@include mat.icon-button-density(-2);

h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.mdc-floating-label {
  width: 100%;
}

.mdc-card {
  padding: 1rem;
}

.mat-mdc-card-title {
  --mat-card-title-text-size: 24px;
  --mat-card-title-text-line-height: 36px;
}
